@font-face {
    font-family: 'PixelMecha';
    src: url('./fonts/PixelMecha.ttf') format('truetype'), url('./fonts/PixelMecha.eot#iefix') format('embedded-opentype'), url('./fonts/PixelMecha.woff') format('woff'), url('./fonts/PixelMecha.svg') format('svg');
    font-size: 20px;
    font-weight: 800;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "PixelMecha", "Roboto Mono", sans-serif;
    /* IE, only works on <img> tags */
    -ms-interpolation-mode: nearest-neighbor;
    /* Firefox */
    image-rendering: crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    /* Chromium + Safari */
    image-rendering: pixelated;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* FireFirefox */
}

*::-webkit-scrollbar {
    display: none;

}

@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap');

iframe {
    display: "none";
}

#suns {
    position: fixed;
    height: 100vh;
    left: 0;
    bottom: 0;
    top: 0;
    filter: brightness(1);
    transition: all 0.5s ease-in-out;
    z-index: 10;

    @media screen and (max-width: 640px) {
        filter: brightness(0);
        transition: all 0.5s ease-in-out;
        mix-blend-mode: difference;
    }
}

.logo-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: flex-end;
    align-items: center;
}

.logo-top {
    overflow: hidden;
    min-height: 40px;
}

#bottom {
    display: flex;
    min-height: 40px;
    overflow: hidden;
    align-items: flex-end;
}

.logo-flex img {
    min-width: 200px;
}


:root {
    --bg-color: rgb(0, 0, 0);
    --main-color: beige;
}

img {
    mix-blend-mode: lighten !important;
}

body {
    background-color: var(--bg-color);
    margin: 0px !important;
}

.main {
    padding: 0 !important;
    margin: 0 !important;
}

#home {
    min-height: 95vh;
    background: var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;


}


h1 {
    font-size: 4rem;
}


a.alink {
    color: var(--main-color);
    overflow: hidden;
    text-overflow: ellipsis;
}

h2 a,
a.alink {
    text-decoration: none;
    white-space: nowrap;
}

img.chains {
    max-width: 20px;
    max-height: 20px;
    border-radius: 0px;
    margin-left: 10px;
}

#foot a,
h2 a {
    color: var(--main-color);
    text-decoration: underline;
}

#foot {
    color: var(--main-color);
    background-color: var(--bg-color);    
    word-wrap: nowrap;
    width: 100vw;
    height: 50px;
    position: fixed;
    bottom: 0px;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    padding:0px 0px 20px 0px;
    display: flex;
    justify-content: center;
}

.copy {
    font-size: 16px;
    letter-spacing: 1px;
}

.socials {
    font-size: 28px;
    padding: 15px 15px 0px 15px;
}

.header {
    max-width: 360px;
    top: 30px;
    position: absolute;
    display: flex;
    background: var(--bg-color);
    flex-direction: column;
    align-items: center;
    max-height: 360px;

    @media screen and (max-width: 480px) {
        max-height: 200px;
        overflow: hidden;

    }
}

.header h2 {
    font-size: 20px;

    @media screen and (max-width: 480px) {
        font-size: 16px;
        padding: 20px;
    }
}

h1 {
    font-size: 48px;
    letter-spacing: 2px;
    display: block;
    white-space: nowrap;
    @media screen and (max-width: 480px) {
        font-size: 36px;
        margin-bottom: 10px!important;
    }
}


h1,
h2 {
    color: var(--main-color);
    font-weight: normal;
    margin-bottom: 24px;
}


.about {
    width: 192px;
    margin-left: 350px;
    height: auto;
    font-size: 16px;
    position: absolute;
    white-space: pre-wrap;
    top: -10px;
    line-height: 1.5rem;
    color: var(--main-color);
    overflow: hidden;

    @media screen and (max-width: 480px) {
        display: none;
    }
}


.about img {
    width: 132px;
    mix-blend-mode: lighten;
    border-radius: 4px;
}

img.saga {
    width: 100%;
    mix-blend-mode: lighten;
    margin-top: 20px;
    border-radius: 0px;
}

img.critters {
    width: 100%;
    mix-blend-mode: lighten;
    margin-top: 20px;
    border-radius: 0px;
}


#soon {
    cursor: url('./fonts/eyes.png') 10 10, s-resize;
}

.hidden {
    margin-left: calc(-50vw + 280px) !important;

    @media screen and (max-width: 640px) {
        margin-left: -350px !important;

    }
}

.hidden a {
    width: 0px;
    visibility: hidden;
    color: transparent !important;
    background-color: transparent !important;
    border-color: var(--bg-color) !important;
}

.hidden a#gallery {
    width: 0px !important;
    visibility: visible !important;
    display: inline-flex !important;
    align-items: flex-end !important;
}

.hidden a#arrow {
    height: 240px;
    position: fixed;
    padding: 0;
    top: 550px;
    width: 25px !important;
    visibility: visible !important;
    margin-left: -25px;
    background-image: url('./fonts/arrow.png');
    z-index: 10;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 480px) {
        top: 0vh;
        height: 100px;
        left: 40px;
        mix-blend-mode: lighten;
    }
}

.hidden a#arrow:hover {
    background-color: var(--bg-color);
    margin-left: -19px;
}

.gallery.hidden {
    display: none;
}

.gallery-flex {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 10px;
    margin-left: 30px;
    top: 20px;
    width: calc(100vw - 350px);
    height: calc(95vh - 300px);
    overflow: scroll;
    transition: all 0.5s ease-in-out;
    padding-bottom: 100px;
    min-height: 450px;

    @media screen and (max-width: 480px) {
        width:100vw;
        top: 0px;
        height: calc(100vh - 180px);

    }

    @media screen and (max-width: 640px) {
        width: calc(100vw - 80px);
        height: calc(100vh - 150px);
    }
}

.gallery-flex figure img,
.gallery-flex figure {
    width: 100%;
    height: 167px;
    border-radius: 4px;
    object-fit: cover;
    cursor: zoom-in;
}

.content {
    min-height: fit-content;
    top: 220px;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    align-items: center;

    @media screen and (max-width: 480px) {        
        top: 180px;

    }
}

.content.hidden {
    max-width: 500px;
    top: 250px;
    min-height: fit-content;
    position: absolute;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 480px) {
        top: 180px;
    }

    @media screen and (max-width: 640px) {
        top: 180px;
        left: 550px; 
    }
}

.content.hidden.about {


    @media screen and (max-width: 640px) {
        display: none; 
    }
}


.groups.hidden p {
    display: none;

    @media screen and (max-width: 480px) {
        display: none;
    }
}

.groups.hidden div {
    border: none !important;

    @media screen and (max-width: 480px) {
        border-left: none;
    }
}

.image-overlay {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100vw;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    z-index: 10;
    background-color: var(--bg-color);
    transition: all 0.5s ease-in-out;
}

.image-overlay img {
    width: auto;
    max-width: 2000px;
    position: absolute;
    cursor: zoom-out;
    height: auto;
    max-height: 80vh;

    @media screen and (max-width: 1920px) {
        max-width: 1600px;
    }

    @media screen and (max-width: 1280px) {
        max-width: 1000px;
    }

    @media screen and (max-width: 640px) {
        max-width: 500px;
    }
}

figcaption {
    z-index: 1000;
    font-size: 1rem;
    position: fixed;
    padding: 4px;
    display: inline-table;
    bottom: 100px;
    text-align: center;
    background-color: var(--bg-color);
    width: 500px;
    left: calc(50% - 250px);
}

figcaption.cptn,
.cptn a {
    visibility: visible !important;
    color: var(--main-color) !important;
}


.image-overlay img.x4 {
    transform: scale(4);
}


.image-carousel-container {
    display: flex;
    flex-direction: row !important;
    overflow-x: hidden;
    height: 530px;
    position: relative;
}

.image-carousel-container:before,
.image-carousel-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 1) 100%);
}

.image-carousel-container:before {
    transform-origin: top left;
}

.image-carousel-container:after {
    transform-origin: bottom left;
}

.image-carousel {
    display: block;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    animation: scroll 90s linear infinite;
    height: 11520px;
    z-index: 0;
    position: relative;
}

.image-carousel img {
    flex: 0 0 auto;
    width: 192px;
    margin: 10px 0px;
    border-radius: 4px;
    object-fit: cover;
    scroll-snap-align: start;
    position: relative;
    z-index: 2;
}


.image-carousel img {
    flex: 0 0 auto;
    width: 192px;
    margin: 10px 0px;
    border-radius: 8px;
    object-fit: cover;
    scroll-snap-align: start;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}